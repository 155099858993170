// src/app/services/auth.service.ts
import { Injectable } from '@angular/core';
import axios, { AxiosResponse } from 'axios';
import { ToastController } from '@ionic/angular';
import { LoadingService } from './loader.services';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = 'https://panel.imigo.app/api';
  private tokenKey = 'auth-token';
  private userData = 'user'

  constructor(private router: Router,private toastController: ToastController,private loadingService: LoadingService) {
    // Load token from storage on service initialization
    this.loadToken();
  }
  async getAuthHeaders(): Promise<any> {
    const token = localStorage.getItem(this.tokenKey); 
    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  }
  async register(user: { username: string; email: string; password: string;name:string;is_male:boolean;nationality:any }): Promise<any> {
    await this.loadingService.present();
    try {
      const response = await axios.post(`${this.apiUrl}/auth/local/register`, user);
      const token = response.data.jwt;
      this.saveToken(token); 
      this.getMe()
      return response.data;
    } catch (error) {
      throw new Error(this.handleError(error));
    }finally {
      await this.loadingService.dismiss();
    }
  }

  async login(identifier: string, password: string): Promise<any> {
    await this.loadingService.present();
    try {
      const response = await axios.post(`${this.apiUrl}/auth/local?populate=deep`, { identifier, password });
      const token = response.data.jwt;
      this.saveToken(token); 
      await this.getMe()
      return response.data;
    } catch (error) {
      throw new Error(this.handleError(error));
    }finally {
      await this.loadingService.dismiss();
    }
  }

  async logout(): Promise<void> {
    this.removeToken(); // Remove token from local storage
    this.removeUserData()
    localStorage.removeItem('vip');
    localStorage.removeItem('notif');
    localStorage.removeItem('hasRegisteredForPush'); // Delete the key

    await this.router.navigateByUrl('login', { replaceUrl: true });
  }

  private saveToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }
  private saveUserData(user: any): void {
    localStorage.setItem( this.userData,JSON.stringify(user));
  }
  private saveVIPData(vip: any): void {
    console.log(vip);
    
    localStorage.setItem( 'vip',vip);
  }
  private loadToken(): void {
    const token = localStorage.getItem(this.tokenKey);
    // Optionally check token validity here if needed
  }

  private removeToken(): void {
    localStorage.removeItem(this.tokenKey);
  }
  private removeUserData(): void {
    localStorage.removeItem(this.userData);
  }
  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  isAuthenticated(): boolean {
    return !!this.getToken(); // Check if token exists
  }


  private handleError(error: any): string {
    let errorMessageKey = 'errors.unknown';
    
    if (error.response) {
      console.log('in errore',error.response.data.error.message);
      
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      if (error.response.data && error.response.data.error) {
        const errorMsg = error.response.data.error.message;
        switch (errorMsg) {
          case 'Invalid identifier or password':
            errorMessageKey = 'errors.wrong_password';
            break;
          case 'Email already taken':
            errorMessageKey = 'errors.email_already_exists';
            break;
          case 'User not found':
            errorMessageKey = 'errors.user_not_found';
            break;
          case 'Validation error':
            errorMessageKey = 'errors.validation_error';
            break;
          case 'email must be a valid email':
          errorMessageKey = 'errors.email_is_not_valid';
          break;
          default:
            errorMessageKey = 'errors.unknown';
        }
      } else if (error.response.data && error.response.data.error) {
        errorMessageKey = 'errors.unknown';
      } else {
        errorMessageKey = `errors.unknown`;
      }
    } else if (error.request) {
      // The request was made but no response was received
      errorMessageKey = 'errors.no_response';
    } else {
      // Something happened in setting up the request that triggered an Error
      errorMessageKey = 'errors.unknown';
    }

    console.error('An error occurred:', errorMessageKey);
    return errorMessageKey;
  }

  async requestPasswordReset(email:any) {
    await this.loadingService.present();
    try {
      await axios.post('https://panel.imigo.app/api/auth/forgot-password', { email: email });
      const toast = await this.toastController.create({
        message: 'Password reset email sent.',
        duration: 2000
      });
      toast.present();
    } catch (error) {
      throw new Error(this.handleError(error));
    }finally {
      await this.loadingService.dismiss();
    }
  }
  async getMe(): Promise<any> {
    try {
      const headers = await this.getAuthHeaders();
      const response: AxiosResponse<any> = await axios.get(`${this.apiUrl}/users/me?populate=picture,nationality`, headers);
      const vip: AxiosResponse<any> = await axios.get(`${this.apiUrl}/vip-users?sort[0]=id:desc&filters[user][id][$eq]=${response.data.id}`, headers);
      
      await this.saveVIPData(vip.data.data.length != 0? vip.data.data[0].attributes.end : 0)
      await this.saveUserData(response.data)
      return response.data
    } catch (error) {
      this.handleError(error);
    }
  }
}
