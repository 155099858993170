import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () => import('./home/home.page').then((m) => m.HomePage),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () => import('./auth/pages/login/login.page').then( m => m.LoginPage)
  },
  {
    path: 'register',
    loadComponent: () => import('./auth/pages/register/register.page').then( m => m.RegisterPage)
  },
  {
    path: 'forgot-password',
    loadComponent: () => import('./auth/pages/forgot-password/forgot-password.page').then( m => m.ForgotPasswordPage)
  },
  {
    path: 'home',
    loadComponent: () => import('./home/pages/home/home.page').then( m => m.HomePage)
  },
  {
    path: 'price-list',
    loadComponent: () => import('./home/pages/priceList/price-list/price-list.page').then( m => m.PriceListPage)
  },
  {
    path: 'price-list-cart',
    loadComponent: () => import('./home/pages/priceList/price-list-cart/price-list-cart.page').then( m => m.PriceListCartPage)
  },
  {
    path: 'forum-list/:type',
    loadComponent: () => import('./home/pages/forum/forum-list/forum-list.page').then( m => m.ForumListPage)
  },
  {
    path: 'post/:id',
    loadComponent: () => import('./home/pages/forum/forum-single/forum-single.page').then( m => m.ForumSinglePage)
  },
  {
    path: 'forum-list/forum-subject-list/:id',
    loadComponent: () => import('./home/pages/forum/forum-subject-list/forum-subject-list.page').then( m => m.ForumSubjectListPage)
  },
  {
    path: 'forum-create',
    loadComponent: () => import('./home/pages/forum/forum-create/forum-create.page').then( m => m.ForumCreatePage)
  },
  {
    path: 'logout',
    loadComponent: () => import('./home/pages/logout/logout.page').then( m => m.LogoutPage)
  },
  {
    path: 'change-profile-picture',
    loadComponent: () => import('./home/pages/change-profile-picture/change-profile-picture.page').then( m => m.ChangeProfilePicturePage)
  },
  {
    path: 'user-profile/:id',
    loadComponent: () => import('./home/pages/user-profile/user-profile.page').then( m => m.UserProfilePage)
  },
  {
    path: 'lists/:category/:title',
    loadComponent: () => import('./home/pages/lists/lists.page').then( m => m.ListsPage)
  },
  {
    path: 'nationality',
    loadComponent: () => import('./auth/pages/nationality/nationality.page').then( m => m.NationalityPage)
  },
  {
    path: 'report',
    loadComponent: () => import('./home/pages/report/report.page').then( m => m.ReportPage)
  },
  {
    path: 'cv-list',
    loadComponent: () => import('./home/pages/user-cv/cv-list/cv-list.page').then( m => m.CvListPage)
  },
  {
    path: 'cv-create',
    loadComponent: () => import('./home/pages/user-cv/cv-create/cv-create.page').then( m => m.CvCreatePage)
  },
  {
    path: 'cv-show/:ai',
    loadComponent: () => import('./home/pages/user-cv/cv-show/cv-show.page').then( m => m.CvShowPage)
  },
  {
    path: 'date-converter',
    loadComponent: () => import('./home/pages/date-converter/date-converter.page').then( m => m.DateConverterPage)
  },
  {
    path: 'ai-creator',
    loadComponent: () => import('./home/pages/user-cv/ai-creator/ai-creator.page').then( m => m.AiCreatorPage)
  },
  {
    path: 'notifications',
    loadComponent: () => import('./home/pages/notifications/notifications.page').then( m => m.NotificationsPage)
  },
  {
    path: 'menus/:id/:title',
    loadComponent: () => import('./home/pages/menus/menus.page').then( m => m.MenusPage)
  },
  {
    path: 'business-databse',
    loadComponent: () => import('./home/pages/business-databse/business-databse.page').then( m => m.BusinessDatabsePage)
  },
  {
    path: 'event-map',
    loadComponent: () => import('./home/pages/event-map/event-map.page').then( m => m.EventMapPage)
  },
  {
    path: 'random-user',
    loadComponent: () => import('./home/pages/random-user/random-user.page').then( m => m.RandomUserPage)
  },
  {
    path: 'business-management/:id',
    loadComponent: () => import('./home/pages/business-management/business-management.page').then( m => m.BusinessManagementPage)
  },
  {
    path: 'business-reminder/:id',
    loadComponent: () => import('./home/pages/business-reminder/business-reminder.page').then( m => m.BusinessReminderPage)
  },
  {
    path: 'vip-list',
    loadComponent: () => import('./home/pages/vip-list/vip-list.page').then( m => m.VipListPage)
  },
];
